
export const getAllChat = async (roomId) => {
    try {
        const res = await fetch(`https://api.balajiwaterpurifier.com/message/getAllMessages/${roomId}`, {
            method: "Get",
            headers: {
                "Content-type": "application/json",
            },
        });
        return await res.json();
    } catch (error) {
        console.log(error);
    }
}