import { useState, useEffect } from "react";
import io from "socket.io-client";
import './ChatApp.css';  // Optional CSS file for styling
import { getAllChat } from "../../api/chatApi";  // Assume this is your API to fetch all chat messages

// Connect to the backend Socket.IO server (replace with your server URL)
const socket = io('https://api.balajiwaterpurifier.com'); // Backend URL

function ChatApp() {
  const [messages, setMessages] = useState([]);  // Store the messages
  const [message, setMessage] = useState("");  // Input message
  const [sender, setSender] = useState("Anand1");  // Hardcoded sender
  const [receiver, setReceiver] = useState("Anand2");  // Hardcoded receiver
  const [roomId, setRoomId] = useState("");  // Chat Room ID
  const [typingStatus, setTypingStatus] = useState("");  // Display typing status

  // Fetch all chat messages for a room (e.g., on component mount or when roomId changes)
  const fetchAllChat = async () => {
    try {
      const res = await getAllChat(roomId);  // Assuming this returns messages for the specified roomId
      setMessages(res);  // Assuming response contains messages in the 'data' field
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  useEffect(() => {
    // Fetch all chat messages when roomId changes
    fetchAllChat();
  }, [roomId]);  // Dependency array ensures the function runs when roomId changes

  useEffect(() => {
    // Join the room and listen for incoming messages via Socket.IO
    socket.emit("join", roomId);

    // Listen for incoming messages
    socket.on("chat message", (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    // Listen for typing status
    socket.on("typing", (status) => {
      setTypingStatus(status);
    });

    // Listen for user leaving
    socket.on("user left", (user) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "System", message: `${user} has left the chat.` }
      ]);
    });

    // Clean up event listeners on component unmount
    return () => {
      socket.off("chat message");
      socket.off("typing");
      socket.off("user left");
    };
  }, [roomId]);  // Re-run this effect when roomId changes

  // Function to send a message to the backend and broadcast it via Socket.IO
  const sendMessage = () => {
    // Validate inputs before proceeding
    if (!sender.trim() || !receiver.trim() || !roomId.trim()) {
      alert("Please fill all required fields: Sender, Receiver, and Room ID.");
      return;
    }
  
    if (!message.trim()) {
      alert("Message cannot be empty.");
      return;
    }
  
    const msg = {
      sender,
      receiver,
      message,
      roomId,
    };
  
    // Emit the message to the backend server via Socket.IO
    socket.emit("chat message", msg);
  
    // Clear the input field after sending
    setMessage("");
  };

  // Function to notify that the user is typing
  const handleTyping = () => {
    socket.emit("typing", `${sender} is typing...`);
  };

  // Function to handle when user leaves the chat
  const handleLeave = () => {
    socket.emit("leave", sender);  // Notify the backend when user leaves
  };

  return (
    <div className="chat-container mt-4">
      <div className="chat-header">
        <h2 className="text-center">Chat Room Using socket.io (Room : {roomId})</h2>
        <h2 className="text-center">You can join any room and chat</h2>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <label className="">Room Id: </label>
              <input
                type="text"
                value={roomId}
                onChange={(e) => setRoomId(e.target.value)}
                placeholder="Enter room ID"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>Sender: </label>
              <input
                type="text"
                value={sender}
                onChange={(e) => setSender(e.target.value)}
                placeholder="Enter your name"
              />
            </div>
            <div className="col-md-12">
              <label>Receiver: </label>
              <input
                type="text"
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
                placeholder="Enter receiver's name"
              />
            </div>
            <div className="col-md-12">
              <div className="message-list">
                {messages?.length > 0 ? (
                  messages.map((msg, index) => (
                    <div key={index} className="message">
                      <strong>{msg.sender}:</strong> {msg.message}
                    </div>
                  ))
                ) : (
                  <p>No messages yet.</p>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="typing-status">{typingStatus}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <input
                type="text"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  handleTyping();  // Notify the server when typing
                }}
                placeholder="Type a message"
                className="message-input"
              />
              <button onClick={sendMessage} className="send-button">Send</button>
              <button onClick={handleLeave} className="leave-button">Leave Chat</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatApp;
