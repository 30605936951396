import "./App.css";
import "../src/styles/Home.css";
import "../src/styles/Loader.css";
import "../src/styles/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

import { HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { DashBoard } from "./pages/DashBoard";
import { Login } from "./pages/Login";
import { PaymentSuccess } from "./pages/PaymentSuccess";
import { AboutUs } from "./pages/AboutUs";
import { CheckOut } from "./pages/CheckOut";
import { ContactUs } from "./pages/ContactUs";
import { Cart } from "./pages/Cart";
import { SiteMap } from "./pages/SiteMap";
import { NotFound } from "./pages/NotFound";
import { TermsConditions } from "./pages/TermsConditions";
import { ReturnRefundPolicy } from "./pages/ReturnRefundPolicy";
import { Disclaimer } from "./pages/Disclaimer";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ProductDetails } from "./pages/ProductDetails";
import { UserProfile } from "./pages/UserProfile";
import { EditProfile } from "./pages/EditProfile";
import { EditAddress } from "./pages/EditAddress";
import { PrivateRoute } from "./constants/PrivateRoute";
import { WaterPurifier } from "./pages/WaterPurifier";
import { VacuumCleaner } from "./pages/VacuumCleaner";
import { WaterDispensers } from "./pages/WaterDispensers";
import { KitchenAppliances } from "./pages/KitchenAppliances";
import { Service } from "./pages/Service";
import AOS from "aos";
import "aos/dist/aos.css";
import { AuthRoute } from "./constants/AuthRoute";
import ChatApp from "./components/liveChat/LiveChat";


function App() {
  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
    <ChatApp/>
    </>
  );
}

export default App;
